import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import '../assets/scss/components/_banner.scss';

// Reusable Banner Component
const BannerImage = ({ imageName, link }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 500)
            }
          }
        }
      }
    }
  `);

  // Find the correct image by relativePath
  const imageNode = data.allFile.edges.find(
    ({ node }) => node.relativePath === imageName
  );

  if (!imageNode) {
    console.error(`Image not found: ${imageName}`);
    return null;
  }

  const image = getImage(imageNode.node.childImageSharp);

  return (
    <a href={link} target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <GatsbyImage image={image} alt="banner" />
    </a>
  );
};

// Exported Components
export const Banner = () => (
  <BannerImage imageName="quranplan-500x125.png" link="https://www.quranplan.com/" />
);

export const AllRecitersBanner = () => (
  <BannerImage imageName="quranplan-500x125.png" link="https://www.quranplan.com/" />
);

export const AllRecitersBannerFirst = () => (
  <BannerImage imageName="quranplan-500x125.png" link="https://www.quranplan.com/" />
);

export const AllRecitersBannerSecond = () => (
  <BannerImage imageName="telegram-500x125.png" link="https://t.me/quranplayermp3" />
);

export const AlltranslationsBannerFirst = () => (
  <BannerImage imageName="quranplan-500x125.png" link="https://www.quranplan.com/" />
);

export const AlltranslationsBannerSecond = () => (
  <BannerImage imageName="telegram-500x125.png" link="https://t.me/quranplayermp3" />
);

export const ShareBanner = () => (
  <BannerImage imageName="quranplan-500x125.png" link="https://t.me/quranplayermp3" />
);
